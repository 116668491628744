import React from "react";
import hostMap from "../../hostMap";
import WujieReact from "wujie-react";
import { useNavigate, useLocation } from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import { loginInfo } from "../../utils";
import {Dropdown, Space, Select, message, Menu} from "antd";

import ajax from './../../api';

const { bus } = WujieReact;
export default function WdwVppManagerPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const childUrl = hostMap("//localhost:9901/");
  const path = location.pathname;
  const { tenantId, staId } = useSelector(state => state.user);

  const gotoBasic = (tid, devIdentify, devId) => {
    bus.$emit('goBasic', tid, devIdentify, devId)
  }

  const props = {
    token: localStorage.getItem("saas-token"),
    tenantId: tenantId,
    stationId: staId,
    menuType: 1,
    jump: (name) => {
      navigate(`/${name}`);
    },
    method: {loginInfo, gotoBasic }
  };
  
  return (
    // 保活模式，name相同则复用一个子应用实例，改变url无效，必须采用通信的方式告知路由变化
    <WujieReact
      width="100%"
      height="100%"
      name="basic"
      url={childUrl}
      sync={!path}
      props={props}
    ></WujieReact>
  );
}
