import React from "react";
import hostMap from "../../hostMap";
import WujieReact from "wujie-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginInfo } from "../../utils";

const { bus } = WujieReact;
export default function DrawerPage() {
  const location = useLocation();
  const navigation = useNavigate();
  const childUrl = hostMap("//localhost:9100/");
  const path = location.pathname;
  const { tenantId, staId } = useSelector(state => state.user);

  const props = {
    token: localStorage.getItem("saas-token"),
    tenantId: tenantId,
    stationId: staId,
    menuType: 1,
    jump: (name) => {
      navigation(`/${name}`);
    },
    method: {loginInfo}
  };
  
  return (
    // 保活模式，name相同则复用一个子应用实例，改变url无效，必须采用通信的方式告知路由变化
    // <WujieReact
    //   width="100%"
    //   height="100%"
    //   name="basic"
    //   url={childUrl}
    //   sync={!path}
    //   props={props}
    // ></WujieReact>
    // <iframe
    //   height="100%"
    //   width="100%"
    //   scrolling="no"
    //   frameBorder="0"
    //   allowtransparency="true"
    //   src={`http://localhost:88/editor/token=${localStorage.getItem('saas-token')}`}
    // />
    <iframe
      height="100%"
      width="100%"
      scrolling="no"
      frameBorder="0"
      allowtransparency="true"
      src={`${window.location.protocol}//${window.location.host}/editor/token=${localStorage.getItem('saas-token')}`}
    />
  );
}