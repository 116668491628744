const map = {
    "//localhost:8600/": "saas/",
    "//localhost:8602/": "wdw/",
    "//localhost:9100/": "editor/", //画板
    "//localhost:9101/": "account/", //画板--账户中心
    "//localhost:7000/": "wdw/eneoverview", // 能耗管理--概况
    "//localhost:7001/": "wdw/enebranch", // 能耗管理--支路能耗统计
    "//localhost:7002/": "wdw/enegroup", // 能耗管理--分组能耗统计
    "//localhost:7003/": "wdw/enebackstep", // 能耗管理--能耗折标分析
    "//localhost:7004/": "wdw/eneyoy", // 能耗管理--能耗同比
    "//localhost:7005/": "wdw/eneqoq", // 能耗管理--能耗环比
    "//localhost:7006/": "wdw/eneflow", // 能耗管理--能耗流向图
    "//localhost:7007/": "wdw/enesetting", // 能耗管理--分类分项配置

    "//localhost:7100/": "wdw/optoverview", // 运维管理--概况
    "//localhost:7101/": "wdw/opttour", // 运维管理--巡视工单
    "//localhost:7102/": "wdw/optrepair", // 运维管理--抢修工单
    "//localhost:7103/": "wdw/optdefect", // 运维管理--缺陷工单
    "//localhost:7104/": "wdw/optmaintenance", // 运维管理--维保工单
    "//localhost:7105/": "wdw/optproject", // 运维管理--项目配置
    "//localhost:7106/": "wdw/opttemplate", // 运维管理--模板配置
    "//localhost:7107/": "wdw/optplan", // 运维管理--巡视计划
    "//localhost:7108/": "wdw/optgroup", // 运维管理--运维班组

    "//localhost:8601/": "wdw/overView", // 驾驶舱--微网概况
    "//localhost:8603/": "wdw/screen", // 驾驶舱--光储充大屏
    "//localhost:8604/": "wdw/electricityOverview",  // 配电监测--运行概况
    "//localhost:8605/": "wdw/powercspt", // 配电监测--用电量统计
    "//localhost:8606/": "wdw/powermgnt", // 配电监测--支路用电统计
    "//localhost:8607/": "wdw/transformer", // 配电监测--时段用电监测
    "//localhost:8608/": "wdw/trend", // 配电监测--时段用电对比
    "//localhost:8609/": "wdw/device", // 配电监测--设备用电对比
    "//localhost:8610/": "wdw/pvOverview", // 配电监测--运行概况
    "//localhost:8611/": "wdw/powerMonitor", // 配电监测--发用电量统计
    "//localhost:8612/": "wdw/inverterMonitor", // 配电监测--逆变器监测
    "//localhost:8613/": "wdw/contrastAnalysis", // 配电监测--逆变器对比
    "//localhost:8614/": "wdw/energyOverview", // 储能监测--运行概况
    "//localhost:8615/": "wdw/deviceMonitor", // 储能监测--pcs/bms
    "//localhost:8616/": "wdw/quantityMonitor", // 储能监测--充放用电量
    "//localhost:8617/": "wdw/chargingOverview", // 停充监测--运行概况
    "//localhost:8618/": "wdw/chargeData", // 停充监测--设备充电数据监控
    "//localhost:8619/": "wdw/chargeCapacity", // 停充监测--设备充电量监测
    "//localhost:8620/": "wdw/chargeStation", // 停充监测--充电站用电详情
    "//localhost:8621/": "wdw/strategyConfigure", // 策略配置--配置
    "//localhost:8622/": "wdw/strategyTable", // 策略配置--列表
    "//localhost:8623/": "wdw/strategyLog", // 策略配置--日志
    "//localhost:8624/": "wdw/manualControl", // 策略配置--手动控制
    "//localhost:8625/": "wdw/simple", // 驾驶舱--简版大屏
    "//localhost:8626/": "wdw/wideScreen", // 驾驶舱--光储充大屏20:9
    "//localhost:8627/": "wdw/energyReport", // 储能监测--报表统计
    "//localhost:8628/": "wdw/energyDataAnalysis", // 储能监测--数据分析

    "//localhost:8700/": "wdw/powerqult", // 配电监测--电能质量监测
    "//localhost:8701/": "wdw/enviroment", // 配电监测--环境监测
    "//localhost:8702/": "wdw/compyoy", // 配电监测--同环比分析
    "//localhost:8703/": "wdw/reportdoc", // 配电监测--用电分析报告
    "//localhost:8704/": "wdw/reportfgp", // 配电监测--尖峰平谷报表
    "//localhost:8705/": "wdw/reportmeter", // 配电监测--远程抄表
    "//localhost:8706/": "wdw/reportload", // 配电监测--负荷极值报表
    "//localhost:8707/": "wdw/feecos", // 配电监测--力调电费分析
    "//localhost:8708/": "wdw/traiff", // 配电监测--基本电费分析
    "//localhost:8709/": "wdw/transfanaly", // 配电监测--变压器经济运行

    "//localhost:8800/": "wdw/ChargeReport", // 停充监测--充电报表
    "//localhost:8801/": "wdw/ChargeAnalysis", // 停充监测--充电报表

    "//localhost:8900/": "vpp/overview", // 虚拟电厂--首页
    "//localhost:8901/": "vpp/plantmgr", // 虚拟电厂 --电厂管理
    "//localhost:8902/": "vpp/invitemgr", // 虚拟电厂 --邀约管理
    "//localhost:8903/": "vpp/replymgr", // 虚拟电厂 --调度管理
    "//localhost:8904/": "vpp/adjust", // 虚拟电厂 --可调节
    "//localhost:8905/": "vpp/userlog", // 虚拟电厂 --用户日志
    "//localhost:8906/": "vpp/monthbill", // 虚拟电厂 --月账单
    "//localhost:8907/": "vpp/clientbill", // 虚拟电厂 --客户对账单
    "//localhost:8908/": "vpp/clienteva", // 虚拟电厂 --客户调控能力
    "//localhost:8909/": "vpp/clientvppinvite", // 虚拟电厂 --客户企业邀约管理
    "//localhost:8910/": "vpp/clientvppbase", // 虚拟电厂 --客户端基线管理

    /*==== wdw 新虚拟电厂模块 start ====*/
    "//localhost:9900/": "wdwvpp/overview", // 虚拟电厂--首页
    "//localhost:9901/": "wdwvpp/plantmgr", // 虚拟电厂 --电厂管理
    "//localhost:9902/": "wdwvpp/invitemgr", // 虚拟电厂 --邀约管理
    "//localhost:9903/": "wdwvpp/replymgr", // 虚拟电厂 --调度管理
    "//localhost:9904/": "wdwvpp/adjust", // 虚拟电厂 --可调节
    "//localhost:9905/": "wdwvpp/userlog", // 虚拟电厂 --用户日志
    "//localhost:9906/": "wdwvpp/monthbill", // 虚拟电厂 --月账单
    "//localhost:9907/": "wdwvpp/clientbill", // 虚拟电厂 --客户对账单
    "//localhost:9908/": "wdwvpp/clienteva", // 虚拟电厂 --客户调控能力
    "//localhost:9909/": "wdwvpp/clientvppinvite", // 虚拟电厂 --客户端企业邀约
    "//localhost:9910/": "wdwvpp/clientvppbase", // 虚拟电厂 -- 客户端基线管理
    "//localhost:9923/": "wdwvpp/screen", // 虚拟电厂 -- 大屏
    /*==== wdw 新虚拟电厂模块 end ====*/

    "//localhost:8911/": "saas/listView", // saas --列表视图
    "//localhost:8912/": "saas/statisticsView", // saas --统计视图
    "//localhost:8913/": "saas/fullScreen", // saas --大屏展示
    "//localhost:8914/": "saas/photovoltaicMonitor", // saas --光伏监测
    "//localhost:8915/": "saas/stationReport", // saas --电站报表
    "//localhost:8916/": "saas/inverterReport", // saas --逆变器报表
    "//localhost:8917/": "saas/incomeReport", // saas --收益报表
    "//localhost:8918/": "saas/powerContrast", // saas --发电量对比
    "//localhost:8919/": "saas/comparative", // saas --同环比分析
    "//localhost:8920/": "saas/realStatus", // saas --运维实时状态
    "//localhost:8921/": "saas/alarm", // saas --运维告警
    "//localhost:8922/": "saas/income", // saas --收益计算
    "//localhost:8923/": "vpp/screenVpp", // 虚拟电厂 --大屏
    "//localhost:8924/": "saas/pvScreen", // saas --大屏展示(新能源集团)

    "//localhost:9000/": "wdwScreen/#/home", // 微电网大屏
    "//localhost:9001/": "wdwScreen/#/widescreen", // 微电网大屏(宽屏)
    "//localhost:9002/": "wdwScreen/#/newScreen", // 微电网大屏(新版)
    "//localhost:9003/": "wdwScreen/#/newScreen2", // 微电网大屏(新版)
    "//localhost:9004/": "wdwScreen/#/screenV3", // 微电网大屏(新版)
    "//localhost:9005/": "wdwScreen/#/screenNewGcc", // 光储充大屏(新版)
    "//localhost:9006/": "wdwScreen/#/screenNewGcc2", // 光储充大屏(新版)
    "//localhost:9149/": "saas/alarmInfo", //告警信息
    "//localhost:9150/": "saas/video", //视频监控

    /*==== wdw 资产管理 ====*/
    "//localhost:9151/": "wdw/ZCEnergyOverView", //驾驶舱-能源概况
    "//localhost:9152/": "wdw/ZCPVOverView", //光伏-能源概况
    "//localhost:9153/": "wdw/ZCTrendAnalysis", //趋势分析
    "//localhost:9154/": "wdw/ZCRunReport", //运行报表
    "//localhost:9155/": "wdw/ZCStationReport", //电站报表
    "//localhost:9156/": "wdw/ZCIncomeReport", //收益报表
    "//localhost:9157/": "wdw/ZCPVIncomeCompute", //光伏-收益计算
    "//localhost:9158/": "wdw/ZCCNIncomeCompute", //储能-收益计算
    "//localhost:9159/": "wdw/ZCInvestIncome", //投资收益
};

const devMap = {
    "//localhost:8600/": "//localhost:8600/saas",
    /*== 能耗管理 70段  ==*/
    "//localhost:7000/": "//localhost:8602/wdw/eneoverview", // 能耗管理--概况
    "//localhost:7001/": "//localhost:8602/wdw/enebranch", // 能耗管理--支路能耗统计
    "//localhost:7002/": "//localhost:8602/wdw/enegroup", // 能耗管理--分组能耗统计
    "//localhost:7003/": "//localhost:8602/wdw/enebackstep", // 能耗管理--能耗折标分析
    "//localhost:7004/": "//localhost:8602/wdw/eneyoy", // 能耗管理--能耗同比
    "//localhost:7005/": "//localhost:8602/wdw/eneqoq", // 能耗管理--能耗环比
    "//localhost:7006/": "//localhost:8602/wdw/eneflow", // 能耗管理--能耗流向图
    "//localhost:7007/": "//localhost:8602/wdw/enesetting", // 能耗管理--分类分项配置

    /*== 运维管理 71段  ==*/
    "//localhost:7100/": "//localhost:8602/wdw/optoverview", // 运维管理--概况
    "//localhost:7101/": "//localhost:8602/wdw/opttour", // 运维管理--巡视工单
    "//localhost:7102/": "//localhost:8602/wdw/optrepair", // 运维管理--抢修工单
    "//localhost:7103/": "//localhost:8602/wdw/optdefect", // 运维管理--缺陷工单
    "//localhost:7104/": "//localhost:8602/wdw/optmaintenance", // 运维管理--维保工单
    "//localhost:7105/": "//localhost:8602/wdw/optproject", // 运维管理--项目配置
    "//localhost:7106/": "//localhost:8602/wdw/opttemplate", // 运维管理--模板配置
    "//localhost:7107/": "//localhost:8602/wdw/optplan", // 运维管理--巡视计划
    "//localhost:7108/": "//localhost:8602/wdw/optgroup", // 运维管理--运维班组

    "//localhost:8601/": "//localhost:8602/wdw/overView", // 驾驶舱--微网概况
    "//localhost:8603/": "//localhost:8602/wdw/screen", // 驾驶舱--光储充大屏
    "//localhost:8626/": "//localhost:8602/wdw/wideScreen", // 驾驶舱--光储充大屏20:9
    "//localhost:8625/": "//localhost:8602/wdw/simple", // 驾驶舱--简版大屏

    "//localhost:8604/": "//localhost:8602/wdw/electricityOverview",  // 配电监测--运行概况
    "//localhost:8605/": "//localhost:8602/wdw/powercspt", // 配电监测--用电量统计
    "//localhost:8606/": "//localhost:8602/wdw/powermgnt", // 配电监测--支路用电统计
    "//localhost:8607/": "//localhost:8602/wdw/transformer", // 配电监测--时段用电监测
    "//localhost:8608/": "//localhost:8602/wdw/trend", // 配电监测--时段用电对比
    "//localhost:8609/": "//localhost:8602/wdw/device", // 配电监测--设备用电对比
    "//localhost:8700/": "//localhost:8602/wdw/powerqult", // 配电监测--电能质量监测
    "//localhost:8701/": "//localhost:8602/wdw/enviroment", // 配电监测--环境监测
    "//localhost:8702/": "//localhost:8602/wdw/compyoy", // 配电监测--同环比分析
    "//localhost:8703/": "//localhost:8602/wdw/reportdoc", // 配电监测--用电分析报告
    "//localhost:8704/": "//localhost:8602/wdw/reportfgp", // 配电监测--尖峰平谷报表
    "//localhost:8705/": "//localhost:8602/wdw/reportmeter", // 配电监测--远程抄表
    "//localhost:8706/": "//localhost:8602/wdw/reportload", // 配电监测--负荷极值报表
    "//localhost:8707/": "//localhost:8602/wdw/feecos", // 配电监测--力调电费分析
    "//localhost:8708/": "//localhost:8602/wdw/traiff", // 配电监测--基本电费分析
    "//localhost:8709/": "//localhost:8602/wdw/transfanaly", // 配电监测--变压器经济运行

    "//localhost:8610/": "//localhost:8602/wdw/pvOverview", // 光伏监测--运行概况
    "//localhost:8611/": "//localhost:8602/wdw/powerMonitor", // 光伏监测--发用电量统计
    "//localhost:8612/": "//localhost:8602/wdw/inverterMonitor", // 光伏监测--逆变器监测
    "//localhost:8613/": "//localhost:8602/wdw/contrastAnalysis", // 光伏监测--逆变器对比
    "//localhost:8614/": "//localhost:8602/wdw/energyOverview", // 储能监测--运行概况
    "//localhost:8615/": "//localhost:8602/wdw/deviceMonitor", // 储能监测--pcs/bms
    "//localhost:8616/": "//localhost:8602/wdw/quantityMonitor", // 储能监测--充放用电量
    "//localhost:8617/": "//localhost:8602/wdw/chargingOverview", // 停充监测--运行概况
    "//localhost:8618/": "//localhost:8602/wdw/chargeData", // 停充监测--设备充电数据监控
    "//localhost:8619/": "//localhost:8602/wdw/chargeCapacity", // 停充监测--设备充电量监测
    "//localhost:8620/": "//localhost:8602/wdw/chargeStation", // 停充监测--充电站用电详情
    "//localhost:8621/": "//localhost:8602/wdw/strategyConfigure", // 策略配置--配置
    "//localhost:8622/": "//localhost:8602/wdw/strategyTable", // 策略配置--列表
    "//localhost:8623/": "//localhost:8602/wdw/strategyLog", // 策略配置--日志
    "//localhost:8624/": "//localhost:8602/wdw/manualControl", // 策略配置--手动控制
    "//localhost:8627/": "//localhost:8602/wdw/energyReport", // 储能监测--报表统计
    "//localhost:8628/": "//localhost:8602/wdw/energyDataAnalysis", // 储能监测--数据分析

    "//localhost:8800/": "//localhost:8602/wdw/ChargeReport", // 停充监测--充电报表
    "//localhost:8801/": "//localhost:8602/wdw/ChargeAnalysis", // 停充监测--充电报表


    /*==== wdw 资产管理 ====*/
    "//localhost:9151/": "//localhost:8602/wdw/ZCEnergyOverView", //驾驶舱-能源概况
    "//localhost:9152/": "//localhost:8602/wdw/ZCPVOverView", //光伏-能源概况
    "//localhost:9153/": "//localhost:8602/wdw/ZCTrendAnalysis", //趋势分析
    "//localhost:9154/": "//localhost:8602/wdw/ZCRunReport", //运行报表
    "//localhost:9155/": "//localhost:8602/wdw/ZCStationReport", //电站报表
    "//localhost:9156/": "//localhost:8602/wdw/ZCIncomeReport", //收益报表
    "//localhost:9157/": "//localhost:8602/wdw/ZCPVIncomeCompute", //光伏-收益计算
    "//localhost:9158/": "//localhost:8602/wdw/ZCCNIncomeCompute", //储能-收益计算
    "//localhost:9159/": "//localhost:8602/wdw/ZCInvestIncome", //投资收益

    "//localhost:8900/": "//localhost:8603/vpp/overview", // 虚拟电厂--首页
    "//localhost:8901/": "//localhost:8603/vpp/plantmgr", // 虚拟电厂 --电厂管理
    "//localhost:8902/": "//localhost:8603/vpp/invitemgr", // 虚拟电厂 --邀约管理
    "//localhost:8903/": "//localhost:8603/vpp/replymgr", // 虚拟电厂 --调度管理
    "//localhost:8904/": "//localhost:8603/vpp/adjust", // 虚拟电厂 --可调节
    "//localhost:8905/": "//localhost:8603/vpp/userlog", // 虚拟电厂 --用户日志
    "//localhost:8906/": "//localhost:8603/vpp/monthbill", // 虚拟电厂 --月账单
    "//localhost:8907/": "//localhost:8603/vpp/clientbill", // 虚拟电厂 --客户对账单
    "//localhost:8908/": "//localhost:8603/vpp/clienteva", // 虚拟电厂 --客户调控能力
    "//localhost:8909/": "//localhost:8603/vpp/clientvppinvite", // 虚拟电厂 --客户端企业邀约
    "//localhost:8910/": "//localhost:8603/vpp/clientvppbase", // 虚拟电厂 -- 客户端基线管理
    "//localhost:8923/": "//localhost:8603/vpp/screen", // 虚拟电厂 -- 大屏

    /*==== wdw 新虚拟电厂模块 start ====*/
    "//localhost:9900/": "//localhost:8603/wdwvpp/overview", // 虚拟电厂--首页
    "//localhost:9901/": "//localhost:8603/wdwvpp/plantmgr", // 虚拟电厂 --电厂管理
    "//localhost:9902/": "//localhost:8603/wdwvpp/invitemgr", // 虚拟电厂 --邀约管理
    "//localhost:9903/": "//localhost:8603/wdwvpp/replymgr", // 虚拟电厂 --调度管理
    "//localhost:9904/": "//localhost:8603/wdwvpp/adjust", // 虚拟电厂 --可调节
    "//localhost:9905/": "//localhost:8603/wdwvpp/userlog", // 虚拟电厂 --用户日志
    "//localhost:9906/": "//localhost:8603/wdwvpp/monthbill", // 虚拟电厂 --月账单
    "//localhost:9907/": "//localhost:8603/wdwvpp/clientbill", // 虚拟电厂 --客户对账单
    "//localhost:9908/": "//localhost:8603/wdwvpp/clienteva", // 虚拟电厂 --客户调控能力
    "//localhost:9909/": "//localhost:8603/wdwvpp/clientvppinvite", // 虚拟电厂 --客户端企业邀约
    "//localhost:9910/": "//localhost:8603/wdwvpp/clientvppbase", // 虚拟电厂 -- 客户端基线管理
    "//localhost:9923/": "//localhost:8603/wdwvpp/screen", // 虚拟电厂 -- 大屏
    /*==== wdw 新虚拟电厂模块 end ====*/

    "//localhost:9000/": "//localhost:8601/wdwScreen/#/home", // 金瑞达 --首页
    "//localhost:9001/": "//localhost:8601/wdwScreen/#/widescreen", // 金瑞达 --首页(适配集控大厅的宽屏幕)
    "//localhost:9002/": "//localhost:8601/wdwScreen/#/newScreen", // 新版大屏
    "//localhost:9003/": "//localhost:8601/wdwScreen/#/newScreen2", // 新版大屏
    "//localhost:9004/": "//localhost:8601/wdwScreen/#/screenV3", // 新版大屏
    "//localhost:9005/": "//localhost:8601/wdwScreen/#/screenNewGcc", // 新版光储充大屏
    "//localhost:9006/": "//localhost:8601/wdwScreen/#/screenNewGcc2", // 新版光储充大屏

    "//localhost:8911/": "//localhost:8911/saas/listView", // saas --列表视图
    "//localhost:8912/": "//localhost:8912/saas/statisticsView", // saas --统计视图
    "//localhost:8913/": "//localhost:8913/saas/fullScreen", // saas --大屏展示
    "//localhost:8914/": "//localhost:8914/saas/photovoltaicMonitor", // saas --光伏监测
    "//localhost:8915/": "//localhost:8915/saas/stationReport", // saas --电站报表
    "//localhost:8916/": "//localhost:8916/saas/inverterReport", // saas --逆变器报表
    "//localhost:8917/": "//localhost:8917/saas/incomeReport", // saas --收益报表
    "//localhost:8918/": "//localhost:8918/saas/powerContrast", // saas --发电量对比
    "//localhost:8919/": "//localhost:8919/saas/comparative", // saas --同环比分析
    "//localhost:8920/": "//localhost:8920/saas/realStatus", // saas --运维实时状态
    "//localhost:8921/": "//localhost:8921/saas/alarm", // saas --运维告警
    "//localhost:8922/": "//localhost:8922/saas/income", // saas --收益计算
    "//localhost:8924/": "//localhost:8913/saas/pvScreen", // saas --大屏展示(新能源集团)
    "//localhost:9150/": "//localhost:8600/saas/video", //视频监控

    "//localhost:9100/": "//localhost:88/", //画板
}

export default function hostMap(host) {
    if (process.env.NODE_ENV === "development") return devMap[host];
    if (process.env.NODE_ENV === "production") {
        return `${window.location.protocol}//${window.location.host}/${map[host]}`;
    }
    return host;
}
